import { uploadAttachments } from "Api/configuration";
import {
  getAllIssueTypes,
  getCustomFieldsByIssueType,
  postTicket,
  getIssueTypesByProjectId,
  getUserByProjects,
  saveAttachToTicket,
} from "Api/jiraIntegration";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";
import { buttonTypes } from "Components/Buttons/buttonTypes";
import IconButton from "Components/Buttons/IconButton";
import Notification from "Notifications/Notification";
import FileTypes from "Pages/FaqGenerator/fileTypes";
import filesData from "Pages/FaqGenerator/fileTypesData";
import { HighlightOffIcon } from "Pages/FaqGenerator/Icons";
import { ICustomField } from "Pages/Jira/interfaces";
import { useState, useEffect } from "react";
import React from "react";

import { useDropzone } from "react-dropzone";

import { useJiraStyles } from "../styles";

const directory = "tasks";

const defaultTicket = {
  summary: "",
  project: null,
  issueType: null,
  fields: {},
  creatorId: localStorage.getItem("userID"),
  creatorName: localStorage.getItem("firstName"),
  creatorSurname: localStorage.getItem("lastName"),
  creatorUsername: localStorage.getItem("username"),
  creatorMail: "",
  attachments: [],
};

const useJira = (): {
  projects;
  handleTicket;
  ticket;
  issueTypes;
  convertFileToIcon;
  loading;
  getRootProps;
  getInputProps;
  customFields;
  handleCustomField;
  handleSave;
} => {
  const [ticket, setTicket] = useState({ ...defaultTicket });
  const [projects, setProjects] = useState<Array<{ id: number; name: string }>>(
    [],
  );
  const [issueTypes, setIssueTypes] = useState([]);
  const [customFields, setCustomFields] = useState<Array<ICustomField>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const classes = useJiraStyles();
  const { translate } = useTranslation();

  const resetTicket = (): void => {
    setTicket({ ...defaultTicket });
    setCustomFields([]);
  };

  const handleTicket = (field, value): void => {
    setTicket((ticket) => ({ ...ticket, [field]: value }));
  };

  const handleCustomField = (id, value): void => {
    setTicket((ticket) => ({
      ...ticket,
      fields: { ...ticket.fields, [id]: value },
    }));
  };

  const fetchProjects = async (): Promise<void> => {
    const userByJiraProjects = Object.keys(
      JSON.parse(localStorage.getItem("authorities")),
    )
      .filter((key) => key.startsWith("jiraprojects_"))
      .join(",");

    const projects = await getUserByProjects(userByJiraProjects);
    if (projects) setProjects(projects.map(({ id, name }) => ({ id, name })));
  };

  const fetchIssueTypes = async (): Promise<void> => {
    const issueTypes = await getAllIssueTypes();
    if (issueTypes)
      setIssueTypes(issueTypes.map(({ id, name }) => ({ id, name })));
  };

  const fetchCustomFieldsByIssueType = async (id): Promise<void> => {
    const customFields = await getCustomFieldsByIssueType(id);
    if (customFields) setCustomFields(customFields);
  };

  const fetchIssueTypesByProjectId = async (id): Promise<void> => {
    const issueTypes = await getIssueTypesByProjectId(id);
    if (issueTypes) setIssueTypes(issueTypes);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted: (files) => {
      handleTicket("attachments", [...ticket.attachments, ...files]);
    },
  });

  const convertFileToIcon = (item, index): React.ReactElement => {
    const fileType = FileTypes[item.split(".")[1].toUpperCase()];
    const name = item;
    return getIconType(
      name,
      index,
      filesData[fileType].buttonType,
      filesData[fileType].icon,
    );
  };

  const handleDeleteAttachment = (order): void => {
    let tempAttachments = ticket.attachments;
    tempAttachments = ticket.attachments.filter(
      (_item, index) => index !== order,
    );
    handleTicket("attachments", tempAttachments);
  };

  const getIconType = (
    name: string,
    index: number,
    buttonType: buttonTypes,
    icon: any,
  ): React.ReactElement => {
    return (
      <React.Fragment key={index}>
        <IconButton
          className={classes.button}
          buttonType={buttonType}
          tooltipText={name}
          Icon={() => <img src={icon} alt={name} className={classes.img} />}
        />
        <HighlightOffIcon
          className={classes.deleteAttachment}
          onClick={() => handleDeleteAttachment(index)}
        />
      </React.Fragment>
    );
  };

  const handleSave = (): Promise<any> => {
    const data = {
      issueTypeId: ticket.issueType?.id,
      projectId: ticket.project?.id,
      requestId: null,
      summary: ticket.summary,
      fields: ticket.fields,
      creatorId: ticket.creatorId,
      creatorName: ticket.creatorName,
      creatorSurname: ticket.creatorSurname,
      creatorUsername: ticket.creatorUsername,
    };

    return postTicket(data)
      .then(async (response) => {
        if (response) {
          setLoading(true);
          if (ticket.attachments?.length > 0) {
            const formdata = new FormData();
            formdata.append("directory", directory);
            for (let i = 0; i < ticket.attachments.length; i++) {
              const item = ticket.attachments[i];
              if (item instanceof File) {
                formdata.append("files", item, item.name);
              }
            }
            const responseData = await uploadAttachments(formdata);
            const attachmentUrls = responseData?.map(
              (item) => "/new-resource/" + item.path,
            );
            const saveAttach = await saveAttachToTicket({
              issueId: response?.id,
              attachmentUrls,
              integration: "SERVER",
            });

            saveAttach !== true &&
              Notification.error(
                translate("jira_ticket_uploaded_attach_error"),
              );
          }
          resetTicket();
          setLoading(false);
          Notification.success(translate("jira_ticket_created"));
        } else throw new Error();
      })
      .catch(() => {
        Notification.error(translate("jira_ticket_created_error"));
      });
  };

  useEffect(() => {
    fetchProjects();
    fetchIssueTypes();
  }, []);

  useEffect(() => {
    if (ticket.issueType?.id)
      fetchCustomFieldsByIssueType(ticket.issueType?.id);
  }, [ticket.issueType?.id]);

  useEffect(() => {
    if (ticket.project?.id) fetchIssueTypesByProjectId(ticket.project?.id);
  }, [ticket.project?.id]);

  return {
    projects,
    handleTicket,
    ticket,
    issueTypes,
    customFields,
    loading,
    convertFileToIcon,
    getRootProps,
    getInputProps,
    handleCustomField,
    handleSave,
  };
};

export default useJira;
