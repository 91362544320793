/* eslint-disable eqeqeq */
import { closeBreak } from "Api/configuration";
import { getAllStatus } from "Api/request";
import sessionStatuses from "Assets/constants/sessionStatuses";
import { store } from "Assets/wrappers/Wrapper";
import LoadingComponent from "Components/Loading/LoadingComponent";
import React, { useEffect } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import Auth from "Store/Actions/Auth";
import RequestActions from "Store/Actions/Request";

export const LogOut = async (): Promise<void> => {
  const breaksState = store.getState().breakReducer;

  const userId = parseInt(localStorage.getItem("userID")),
    closeBreakId = parseInt(breaksState.closeBreakId),
    closeAllowCallTime = parseInt(breaksState.allowCallTime);

  breaksState.selectedBreakId != "default" &&
    (await closeBreak(userId, closeBreakId, closeAllowCallTime, true));
  sessionStorage.setItem("logOut", "true");
  localStorage.removeItem("locationHref");
  localStorage.removeItem("token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("firstName");
  localStorage.removeItem("lastName");
  localStorage.removeItem("username");
  localStorage.removeItem("autoCall");
  localStorage.removeItem("onlyChildClassifiersAllowed");
  localStorage.removeItem("classifierSearchCharacterLimit");
  localStorage.removeItem("sipNumber");
  localStorage.removeItem("userID");
  localStorage.removeItem("autoAcw");
  localStorage.removeItem("autoCallTimeout");
  localStorage.removeItem("authorities");
  localStorage.removeItem("phoneConnected");
  localStorage.removeItem("chatConnected");
  window.location.reload();
};

export default function Authentication(props) {
  const authState = useSelector((state: RootStateOrAny) => state.authReducer);
  const auth = new Auth(useDispatch());
  const request = new RequestActions(useDispatch());
  const requestState = useSelector(
    (state: RootStateOrAny) => state.requestReducer,
  );

  const fetchAllStatus = async (): Promise<void> => {
    if (requestState.allStatus?.length === 0) {
      const response = await getAllStatus();
      response.status === 200 && request.setAllStatuses(response.data);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      fetchAllStatus();
      auth.setSessionStatus(sessionStatuses.active);
    } else {
      auth.setSessionStatus(sessionStatuses.inactive);
    }
  }, []);

  if (authState.sessionStatus !== sessionStatuses.start) return props.children;
  return <LoadingComponent />;
}
