import { RequestContextStore } from "Components/Layout/AppLayout";

import { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";

import Request from "Store/Actions/Request";

import { defaultStatus } from "../constants";

export const useStatusSelector = (): any => {
  const [currentStatus, setCurrentStatus] = useState(defaultStatus);
  const requestContextStore: any = useContext(RequestContextStore);

  const requestState = useSelector(
    (state: RootStateOrAny) => state.requestReducer,
  );
  const request = new Request(useDispatch());

  useEffect(() => {
    (async function () {
      if (
        requestState.options[requestState.menuType]?.length === 0 &&
        requestState.allStatus?.length > 0
      )
        request.setRequestTypes(requestState.menuType, requestState.allStatus);
    })();
  }, [requestState.menuType, requestState.options, requestState.allStatus]);

  useEffect(() => {
    setCurrentStatus(
      requestState.chosenOptions[requestState.menuType][
        requestContextStore.getRequestId()
      ] ?? defaultStatus,
    );
  }, [
    requestState.menuType,
    requestState.chosenOptions,
    requestContextStore.getRequestId(),
  ]);

  return {
    currentStatus,
    requestState,
    request,
    requestContextStore,
    setCurrentStatus,
  };
};

useStatusSelector.displayName = "useStatusSelector";
