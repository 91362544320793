/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/ban-types */
import menuTypes from "Assets/constants/menuTypes";
import { useCallback } from "react";

import { requestActions as actionTypes } from "./actionTypes";

interface ISelectedRequestType {
  id: number;
  name: string;
}

export default class Request {
  dispatch: Function = () => {};
  constructor(dispatch: Function) {
    this.dispatch = dispatch;
  }

  setRequestTypes = useCallback(
    (menuType: menuTypes, options: Array<any>) =>
      this.dispatch({
        type: actionTypes.SET_REQUEST_TYPES,
        options,
        menuType,
      }),
    [this],
  );

  addRequestOption = useCallback(
    (menuType: menuTypes, requestId: string, option: any) =>
      this.dispatch({
        type: actionTypes.ADD_CHOSEN_OPTION,
        requestId,
        menuType,
        option,
      }),
    [this],
  );

  removeRequestOption = useCallback(
    (menuType: menuTypes, requestId: string) =>
      this.dispatch({
        type: actionTypes.REMOVE_OPTION,
        requestId,
        menuType,
      }),
    [this],
  );

  setSelectedRequestType = useCallback(
    (request: ISelectedRequestType) =>
      this.dispatch({
        type: actionTypes.SET_SELECTED_REQUEST_TYPE,
        request,
      }),
    [this],
  );

  setSelectedRequestDataOnEditRequest = useCallback(
    (request: ISelectedRequestType, menuType: menuTypes) =>
      this.dispatch({
        type: actionTypes.SET_REQUEST_DATA_ON_EDIT_REQUEST,
        request,
        menuType,
      }),
    [this],
  );

  setMenuType = useCallback(
    (menuType: menuTypes | null) =>
      this.dispatch({
        type: actionTypes.SET_MENU_TYPE,
        menuType,
      }),
    [this],
  );

  setNote = useCallback(
    (customerNote: string) =>
      this.dispatch({
        type: actionTypes.SET_NOTE,
        customerNote,
      }),
    [this],
  );

  setShowRegistration = useCallback(
    (showRegistration: boolean) =>
      this.dispatch({
        type: actionTypes.SET_SHOW_REGISTRATION,
        showRegistration,
      }),
    [this],
  );

  setIsActivating = useCallback(
    (isActivating: boolean) =>
      this.dispatch({
        type: actionTypes.IS_ACTIVATING,
        isActivating,
      }),
    [this],
  );

  setRequestSending = useCallback(
    (isSending: boolean) =>
      this.dispatch({
        type: actionTypes.SET_REQUEST_SENDING,
        isSending,
      }),
    [this],
  );

  setEditMode = useCallback(
    (editCustomerId: boolean) =>
      this.dispatch({
        type: actionTypes.SET_EDIT_CUSTOMER_ID,
        editCustomerId,
      }),
    [this],
  );

  setLeftPanelOpen = useCallback(
    (isLeftPanelOpen: boolean) =>
      this.dispatch({
        type: actionTypes.SET_LEFT_PANEL_OPEN,
        isLeftPanelOpen,
      }),
    [this],
  );

  setIsEditRequest = useCallback(
    (isEditRequest: boolean) =>
      this.dispatch({
        type: actionTypes.SET_IS_EDIT_REQUEST,
        isEditRequest,
      }),
    [this],
  );

  setAllStatuses = useCallback(
    (allStatus: Object[]) =>
      this.dispatch({
        type: actionTypes.SET_ALL_STATUS,
        allStatus,
      }),
    [this],
  );
}
