import React from "react";

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const shortMonthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const formatAMPM = (date) => {
  let hours = date.slice(11, 13);
  let minutes = date.slice(14, 16);
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes.toString().padStart(2, "0");
  const strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

export const formatDateTime = (date) => {
  const monthAsName = monthNames[date.slice(5, 7) - 1];
  const day = date.slice(8, 9) !== "0" ? date.slice(8, 10) : date.slice(9, 10);
  const year = date.slice(0, 4);
  const time = formatAMPM(date);

  const fullName = (
    <span>
      <strong>{monthAsName} </strong>
      {day}, {year} at {time}
    </span>
  );

  return fullName;
};

export const parseDateFromString = (value): object => {
  const [day, month, year] = value.split("/").map(Number);
  const date = new Date(year, month - 1, day);
  return date;
};

export const formatDateTimeSecond = (date) => {
  const monthAsName = monthNames[date.slice(5, 7) - 1];
  const day = date.slice(8, 9) !== "0" ? date.slice(8, 10) : date.slice(9, 10);
  const year = date.slice(0, 4);
  const time = formatAMPM(date);

  const fullName = (
    <span>
      {monthAsName} {day}, {year} at {time}
    </span>
  );

  return fullName;
};

export const formatDateTimeToSecond = (date) => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const secounds = date.getSeconds();
  return hours * 60 + minutes * 60 + secounds;
};

export const formatMonthDateTime = (date) => {
  const monthAsName = monthNames[date.slice(5, 7) - 1];
  const day = date.slice(8, 9) !== "0" ? date.slice(8, 10) : date.slice(9, 10);
  const time = formatAMPM(date);

  const fullName = (
    <span>
      {monthAsName} {day} at {time}
    </span>
  );

  return fullName;
};

export const formatMonthDate = (date) => {
  const year = date.slice(0, 4);
  const monthAsName = shortMonthNames[date.slice(5, 7) - 1];
  const day = date.slice(8, 9) !== "0" ? date.slice(8, 10) : date.slice(9, 10);

  const fullName = (
    <span>
      <strong>{monthAsName}</strong> {day}, {year}
    </span>
  );

  return fullName;
};

export const formatDateString = (date: Date): string => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const timeSince = (date) => {
  const seconds = Math.floor(
    (new Date(Date.now() + 45000).valueOf() - date) / 1000,
  );

  let interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + "y";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + "m";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + "d";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + "h";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + "min";
  }
  return Math.floor(seconds) + "sec";
};

export const convertCallDurationToSeconds = (callDuration: string): number => {
  const [hours, minutes, seconds] = callDuration?.split(":").map(Number);
  const totalSeconds = hours * 3600 + minutes * 60 + seconds;
  return totalSeconds;
};

export const convertToSeconds = (dateString): number => {
  const date = new Date(dateString);
  return date.getHours() * 3600 + date.getMinutes() * 60 + date.getSeconds();
};

export const convertTimeStringToSeconds = (
  timeString: string | null,
): number | null => {
  if (!timeString) {
    return null;
  }
  const timeParts = timeString.split(":");
  const seconds = +timeParts[0] * 3600 + +timeParts[1] * 60 + +timeParts[2];
  return seconds;
};
