import axios from "Api/axios";
import WebConf, { RequestServerUrl } from "Config/WebConf";
import { fetchData } from "networking";

import { exportBlobByUrl } from "Utils/Utils";

import { IRequestSearchData, IRequestExportData } from "./interfaces";

// GET
export const getCustomerRequests = async (
  customerId: number,
  page: number = 0,
  size: number = 1000,
) =>
  axios
    .get(
      RequestServerUrl +
        WebConf.request.getRequestsByCustomerId +
        "?crmId=" +
        customerId +
        "&page=" +
        page +
        "&size=" +
        size,
    )
    .then((response) => response && response.data);

export const getRequestById = (id: string | number) =>
  axios
    .get(RequestServerUrl + WebConf.request.getRequestById + id)
    .then((response) => response && response.data);

export const getRequestFields = () =>
  axios
    .get(RequestServerUrl + WebConf.request.getRequestFields)
    .then((response) => response && response.data);

export const getAllStatus = () =>
  axios
    .get(RequestServerUrl + WebConf.request.getAllStatus)
    .then((response) => response);

// DELETE
export const removeTaskFromRequest = async (
  requestId: number,
  taskId: number,
) =>
  axios
    .delete(
      `${RequestServerUrl}/${WebConf.request.removeTaskFromRequest}?requestId=${requestId}&taskId=${taskId}`,
    )
    .then((response) => (response && response.status === 200 ? true : false));

// POST
export const searchAllRequest = async (data: IRequestSearchData) =>
  axios
    .post(RequestServerUrl + WebConf.request.searchAllRequest, data)
    .then((response) => response && response.data);

export const searchMyRequest = async (data: IRequestSearchData) =>
  axios
    .post(RequestServerUrl + WebConf.request.searchMyRequest, data)
    .then((response) => response && response.data);

export const saveCallRequest = (data, missedCallId) => {
  const requestId = missedCallId ? "?missedCallId=" + missedCallId : "";

  return axios
    .post(
      RequestServerUrl + WebConf.request.saveCallRequest + requestId ?? "",
      data,
    )
    .then((response) => response && response.data);
};

export const saveChatRequest = async (data) => {
  return axios
    .post(RequestServerUrl + WebConf.request.saveChatRequest, data)
    .then((response) => response && response.data);
};

export const exportExcelByUrl = async (data: IRequestExportData) => {
  const body: BodyInit = JSON.stringify(data);

  const params: RequestInit = {
    method: "POST",
    body: body,
  };
  const response = await fetchData(
    RequestServerUrl + WebConf.request.exportRequest,
    params,
  );
  if (response && response.status === 200) {
    try {
      const blob = await response.blob();
      exportBlobByUrl(blob);
    } catch (error) {
      return [];
    }
  } else return {};
};
