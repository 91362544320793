import sessionStatuses from "Assets/constants/sessionStatuses";

import AppLayout from "Components/Layout/AppLayout";
import LoadingComponent from "Components/Loading/LoadingComponent";

import {
  allowedRoutes,
  appPrefix,
  availablePages,
  permissions,
} from "Config/config";

import React, { Suspense } from "react";
import { useSelector, RootStateOrAny } from "react-redux";
import { Route, Switch } from "react-router-dom";

import { permit } from "Utils/Utils";

const Customers = LazyImportPage("Crm/Crm");
const Companies = LazyImportPage("Crm/Crm");
const RequestTable = LazyImportPage("Request/Request");
const Categories = LazyImportPage("FaqGenerator/FaqGenerator");
const SMSTemplates = LazyImportPage("SMSTemplates/Templates");
const MessageTemplates = LazyImportPage("MessageTemplates/MessageTemplates");
const AutoReplyMessage = LazyImportPage("AutoReplyMessage/AutoReplyMessage");
const PreDefinedMessage = LazyImportPage("PreDefinedMessage/PreDefinedMessage");
const Classificators = LazyImportPage("Classificators/Classificators");
const ClassificatorReportPage = LazyImportPage(
  "Report/ClassificatorReport/ClassificatorReport",
);
const QueueReportPage = LazyImportPage("Report/QueueReport/QueueReport");
const CallQueueReportPage = LazyImportPage(
  "Report/CallQueueReport/CallQueueReport",
);
const ChatQueueReportPage = LazyImportPage(
  "Report/ChatQueueReport/ChatQueueReport",
);
const CrmDataReportPage = LazyImportPage("Report/CrmDataReport/CrmDataReport");
const AgentWorkReportPage = LazyImportPage(
  "Report/AgentWorkReport/AgentWorkReport",
);
const SummaryCallReportPage = LazyImportPage(
  "Report/SummaryCallReport/SummaryCallReport",
);
const SummaryChatReportPage = LazyImportPage(
  "Report/SummaryChatReport/SummaryChatReport",
);
const AgentSMMReportPage = LazyImportPage(
  "Report/AgentSMMReport/AgentSMMReport",
);
const ChatReportPage = LazyImportPage("Report/ChatReport/ChatReport");
const CallReportPage = LazyImportPage("Report/CallReport/CallReport");
const AgentCallReportPage = LazyImportPage(
  "Report/AgentCallReport/AgentCallReport",
);
const AgentSessionReportPage = LazyImportPage(
  "Report/AgentSessionReport/AgentSessionReport",
);
const TraceByLocationReportPage = LazyImportPage(
  "Report/TraceByLocationReport/TraceByLocationReport",
);
const MissedCallReportPage = LazyImportPage(
  "Report/MissedCallReport/MissedCallReport",
);

const SmsSenderReportPage = LazyImportPage(
  "Report/SmsSenderReport/SmsSenderReport",
);
const FCRReportPage = LazyImportPage("Report/FCRReport/FCRReport");
const IVRReportPage = LazyImportPage("Report/IVRReport/IVRReport");
const TaskReportPage = LazyImportPage("Report/TaskReport/TaskReport");
const Task2ReportPage = LazyImportPage("Report/TaskReport2/TaskReport2");
const HomePage = LazyImportPage("Home/Home");
const LoginPage = LazyImportPage("Login/Login");
const UserManagementPage = LazyImportPage(
  "Configuration/UserManagement/UserManagement",
);
const RoleManagementPage = LazyImportPage(
  "Configuration/RoleManagement/RoleManagement",
);
const IvrAnnounceManagementPage = LazyImportPage(
  "Configuration/IvrAnnounceManagement/IvrAnnounceManagement",
);
const BreakManagementPage = LazyImportPage(
  "Configuration/BreakManagement/BreakManagement",
);
const TaskManagementPage = LazyImportPage(
  "Configuration/TaskManagement/TaskManagement",
);
const CallbackManagementPage = LazyImportPage(
  "Configuration/CallbackManagement/CallbackManagement",
);
const QueueManagementPage = LazyImportPage(
  "Configuration/QueueManagement/QueueManagement",
);
const DictionaryManagementPage = LazyImportPage(
  "Configuration/DictionaryManagement/DictionaryManagement",
);
const TaskManagement = LazyImportPage("TaskManagement/TaskManagement");
const BreaksPage = LazyImportPage("Breaks/Breaks");
const CallbackPage = LazyImportPage("Callback/Management/Callback");
const CallbackAdmin = LazyImportPage("Callback/Manual/Main");
const Dashboards = LazyImportPage("Monitoring/Dashboards/Dashboards");
const Dashboard = LazyImportPage("Monitoring/Dashboard/Dashboard");
const SpyCall = LazyImportPage("SpyCall/SpyCall");
const SocialMedia = LazyImportPage("SocialMedia/SocialMediaContainer");
const NoMatchPage = LazyImportPage("NoMatchPage");
const IVRSurvey = LazyImportPage("Evaluation/IVRSurvey/Main");
const QualityControl = LazyImportPage(
  "Evaluation/Configuration/QualityControl/QualityControl",
);
const CallCriteria = LazyImportPage(
  "Evaluation/Configuration/Criteria/CallCriteria",
);
const ChatCriteria = LazyImportPage(
  "Evaluation/Configuration/Criteria/ChatCriteria",
);
const Admin = LazyImportPage("Evaluation/Admin/Main");
const Operation = LazyImportPage("Evaluation/Operation/Main");

const JiraConfiguration = LazyImportPage("Jira/Configuration/Configuration");
const JiraTickets = LazyImportPage("Jira/Tickets/Tickets");
const JiraReport = LazyImportPage("Jira/Report/JiraReport");

const AlreadyAuthorizedPage = LazyImportPage(
  "Pages/AlreadyAuthorizedPage/AlreadyAuthorizedPage",
);

function LazyImportPage(
  route,
): React.LazyExoticComponent<React.ComponentType<any>> {
  return React.lazy(() => import(`./${route}`));
}

/*
 * Routing with lazy imported pages.
 */
const Pages = (): React.ReactElement => {
  const authState = useSelector((state: RootStateOrAny) => state.authReducer);

  return (
    <Suspense fallback={<LoadingComponent />}>
      {authState.sessionStatus === sessionStatuses.active && (
        <AppLayout>
          <Switch>
            <Route
              exact
              path={appPrefix + availablePages.customers}
              component={permit(Customers, permissions.crm_customer_tab)}
            />
            <Route
              exact
              path={appPrefix + availablePages.companies}
              component={permit(Companies, permissions.crm_company_tab)}
            />
            <Route
              exact
              path={appPrefix + availablePages.requestTable}
              component={permit(RequestTable)}
            />
            <Route
              exact
              path={appPrefix + availablePages.categories}
              component={permit(Categories)}
            />
            <Route
              exact
              path={appPrefix + availablePages.smsTemplates}
              component={permit(SMSTemplates, permissions.sms_get)}
            />
            <Route
              exact
              path={appPrefix + availablePages.messageTemplates}
              component={permit(
                MessageTemplates,
                permissions.omnichannel_get_message_templates,
              )}
            />
            <Route
              exact
              path={appPrefix + availablePages.autoReplyMessage}
              component={permit(
                AutoReplyMessage,
                permissions.generator_auto_reply_message,
              )}
            />
            <Route
              exact
              path={appPrefix + availablePages.preDefinedMessage}
              component={permit(
                PreDefinedMessage,
                permissions.generator_predefined_message,
              )}
            />
            <Route
              exact
              path={appPrefix + availablePages.classificators}
              component={permit(Classificators, permissions.classifier_list)}
            />
            <Route
              exact
              path={appPrefix + availablePages.classificatorReport}
              component={permit(
                ClassificatorReportPage,
                permissions.report_classificator,
              )}
            />
            <Route
              exact
              path={appPrefix + availablePages.queueReport}
              component={permit(QueueReportPage, permissions.report_queue)}
            />
            <Route
              exact
              path={appPrefix + availablePages.callQueueReport}
              component={permit(
                CallQueueReportPage,
                permissions.report_call_queue,
              )}
            />
            <Route
              exact
              path={appPrefix + availablePages.chatQueueReport}
              component={permit(
                ChatQueueReportPage,
                permissions.report_chat_queue,
              )}
            />
            <Route
              exact
              path={appPrefix + availablePages.agentWorkReport}
              component={permit(
                AgentWorkReportPage,
                permissions.report_agent_work,
              )}
            />
            <Route
              exact
              path={appPrefix + availablePages.summaryCallReport}
              component={permit(
                SummaryCallReportPage,
                permissions.report_summary_call,
              )}
            />
            <Route
              exact
              path={appPrefix + availablePages.summaryChatReport}
              component={permit(
                SummaryChatReportPage,
                permissions.report_summary_chat,
              )}
            />
            <Route
              exact
              path={appPrefix + availablePages.callReport}
              component={permit(CallReportPage, permissions.report_call)}
            />
            <Route
              exact
              path={appPrefix + availablePages.chatReport}
              component={permit(ChatReportPage, permissions.report_chat)}
            />
            <Route
              exact
              path={appPrefix + availablePages.agentCallReport}
              component={permit(
                AgentCallReportPage,
                permissions.report_agent_call,
              )}
            />
            <Route
              exact
              path={appPrefix + availablePages.traceByLocationReport}
              component={permit(
                TraceByLocationReportPage,
                permissions.report_trace_by_location,
              )}
            />
            <Route
              exact
              path={appPrefix + availablePages.missedCallReport}
              component={permit(
                MissedCallReportPage,
                permissions.report_missed_call,
              )}
            />
            <Route
              exact
              path={appPrefix + availablePages.taskReport}
              component={permit(TaskReportPage, permissions.report_task)}
            />
            <Route
              exact
              path={appPrefix + availablePages.task2Report}
              component={permit(Task2ReportPage, permissions.report_task2)}
            />
            <Route
              exact
              path={appPrefix + availablePages.crmDataReport}
              component={permit(CrmDataReportPage, permissions.report_crm_data)}
            />
            <Route
              exact
              path={appPrefix + availablePages.agentSMMReport}
              component={permit(
                AgentSMMReportPage,
                permissions.report_agent_session,
              )}
            />
            <Route
              exact
              path={appPrefix + availablePages.agentSessionReport}
              component={permit(
                AgentSessionReportPage,
                permissions.report_agent_session,
              )}
            />
            <Route
              exact
              path={appPrefix + availablePages.agentSessionReport}
              component={permit(
                AgentSessionReportPage,
                permissions.report_agent_session,
              )}
            />
            <Route
              exact
              path={appPrefix + availablePages.smsSenderReport}
              component={permit(
                SmsSenderReportPage,
                permissions.sms_sender_report,
              )}
            />
            <Route
              exact
              path={appPrefix + availablePages.fcrReport}
              component={permit(FCRReportPage, permissions.report_fcr)}
            />
            <Route
              exact
              path={appPrefix + availablePages.ivrReport}
              component={permit(IVRReportPage, permissions.report_ivr)}
            />
            <Route
              exact
              path={appPrefix + availablePages.userManagement}
              component={permit(
                UserManagementPage,
                permissions.configuration_user_management,
              )}
            />
            <Route
              exact
              path={appPrefix + availablePages.roleManagement}
              component={permit(
                RoleManagementPage,
                permissions.configuration_role_management,
              )}
            />
            <Route
              exact
              path={appPrefix + availablePages.ivrAnnounceManagement}
              component={permit(
                IvrAnnounceManagementPage,
                permissions.configuration_ivr_announce,
              )}
            />
            <Route
              exact
              path={appPrefix + availablePages.breakManagement}
              component={permit(
                BreakManagementPage,
                permissions.configuration_break_management,
              )}
            />
            <Route
              exact
              path={appPrefix + availablePages.taskManagement}
              component={permit(
                TaskManagementPage,
                permissions.configuration_task_management,
              )}
            />
            <Route
              exact
              path={appPrefix + availablePages.callbackManagement}
              component={permit(
                CallbackManagementPage,
                permissions.configuration_auto_callback_management,
              )}
            />
            <Route
              exact
              path={appPrefix + availablePages.queueManagement}
              component={permit(
                QueueManagementPage,
                permissions.configuration_queue_management,
              )}
            />
            <Route
              exact
              path={appPrefix + availablePages.dictionaryManagement}
              component={permit(
                DictionaryManagementPage,
                permissions.task_list_access,
              )}
            />
            <Route
              path={appPrefix + availablePages.tasks}
              component={permit(
                TaskManagement,
                permissions.task_mngmt_tasks_page,
              )}
            />
            <Route
              path={appPrefix + availablePages.breaks}
              component={permit(
                BreaksPage,
                permissions.break_permission_management,
              )}
            />
            <Route
              exact
              path={appPrefix + availablePages.callback}
              component={permit(CallbackPage, permissions.callback_agent)}
            />
            <Route
              path={appPrefix + availablePages.callbackAdmin}
              component={permit(CallbackAdmin, permissions.callback_admin)}
            />
            <Route
              exact
              path={appPrefix + availablePages.dashboards}
              component={permit(Dashboards)}
            />
            <Route
              path={appPrefix + availablePages.dashboard}
              component={permit(Dashboard)}
            />
            <Route
              path={appPrefix + availablePages.spyCall}
              component={permit(SpyCall, permissions.monitoring_spy_call)}
            />
            <Route
              path={appPrefix + availablePages.socialMedia}
              component={permit(SocialMedia, permissions.social_media)}
            />
            <Route
              path={appPrefix + availablePages.ivrSurvey}
              component={permit(
                IVRSurvey,
                permissions.evaluation_ivr_survey_menu,
              )}
            />
            <Route
              path={appPrefix + availablePages.qualityControl}
              component={permit(
                QualityControl,
                permissions.evaluation_configuration_quality_control,
              )}
            />
            <Route
              path={appPrefix + availablePages.callCriteria}
              component={permit(
                CallCriteria,
                permissions.evaluation_configuration_call_criteria,
              )}
            />
            <Route
              path={appPrefix + availablePages.chatCriteria}
              component={permit(
                ChatCriteria,
                permissions.evaluation_configuration_chat_criteria,
              )}
            />
            <Route
              path={appPrefix + availablePages.admin}
              component={permit(Admin, permissions.evaluation_admin_menu)}
            />
            <Route
              path={appPrefix + availablePages.operation}
              component={permit(
                Operation,
                permissions.evaluation_operation_menu,
              )}
            />
            <Route
              path={appPrefix + availablePages.jiraConfiguration}
              component={permit(
                JiraConfiguration,
                permissions.jira_configuration,
              )}
            />
            <Route
              path={appPrefix + availablePages.jiraTickets}
              component={permit(JiraTickets, permissions.jira_ticket)}
            />
            <Route
              path={appPrefix + availablePages.jiraReport}
              component={permit(JiraReport, permissions.jira_report)}
            />
            <Route
              exact
              path={appPrefix + availablePages.index}
              component={permit(HomePage)}
            />
            <Route component={NoMatchPage} />
          </Switch>
        </AppLayout>
      )}
      {authState.sessionStatus !== sessionStatuses.active && (
        <>
          <Route path={appPrefix + allowedRoutes.login} component={LoginPage} />
          <Route
            path={appPrefix + allowedRoutes.alreadyAuthorized}
            component={AlreadyAuthorizedPage}
          />
        </>
      )}
    </Suspense>
  );
};

export default Pages;

Pages.displayName = "Pages";
